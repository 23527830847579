import React from "react";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";
import '../MobileSideNavbar.css';

import downloadIcon from 'assets/img/images/download-icon.png'
import videoIcon from 'assets/img/images/video-icon-img.png'
import icon1 from 'assets/img/images/icons/tech-support-icon.png'
import icon2 from 'assets/img/images/icons/servicerequest-icon.png'
import icon3 from 'assets/img/images/icons/find-represntative-icon.png'
import icon4 from 'assets/img/images/icons/request-quote-icon.png'
import icon5 from 'assets/img/images/icons/faqs-icon.png'
import icon6 from 'assets/img/images/icons/shippingandreturn-icon.png'
import icon8 from 'assets/img/images/icons/warranty-icon.png'
import icon9 from 'assets/img/images/icons/productRegistration-icon.png'
import icon10 from 'assets/img/images/icons/privacyInqury-img.png'

function SupportPage({ mobileNavbarState }){
  return(
    <>
      <div id="support" class="sidenav">
        <ListGroup flush>
          <ListGroupItem
            tag={Link}
            to="/support/downloads"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={downloadIcon}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            Downloads
            <hr />
          </ListGroupItem>
          <ListGroupItem
            tag={Link}
            to="/support/howtovideos"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={videoIcon}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            How-To Videos
            <hr />
          </ListGroupItem>
          <ListGroupItem
            tag={Link}
            to="/support/techsupport"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={icon1}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            Tech Support
            <hr />
          </ListGroupItem>
          <ListGroupItem
            tag={Link}
            to="/support/servicerequest"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={icon2}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            Service Request
            <hr />
          </ListGroupItem>
          <ListGroupItem
            tag={Link}
            to="/support/techsupport"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={icon3}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            Find a Representative
            <hr />
          </ListGroupItem>
          <ListGroupItem
            tag={Link}
            to="/support/getquote"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={icon4}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            Request Quote
            <hr />
          </ListGroupItem>
          <ListGroupItem
            tag={Link}
            to="/support/faq"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={icon5}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            FAQ's
            <hr />
          </ListGroupItem>
          <ListGroupItem
            tag={Link}
            to="/support/shipping"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={icon6}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            Shipping & Returns
            <hr />
          </ListGroupItem>
          <ListGroupItem
            tag={Link}
            to="/support/warranty"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={icon8}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            Warranty
            <hr />
          </ListGroupItem>
          <ListGroupItem
            tag={Link}
            to="/support/privacyinquiry"
            onClick={() => mobileNavbarState()}
          >
            <img
              src={icon10}
              className='common-icon-img'
              alt=""
              style={{
                'width': '30px',
                'height': '30px',
                'margin-left': '10px',
                'margin-right': '10px'
              }}
            />
            Privacy Inqury
            <hr />
          </ListGroupItem>
        </ListGroup>
      </div>
    </>
  )
}

export default SupportPage;
