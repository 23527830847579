import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import '../MobileSideNavbar.css';

function MeterPage({ openMenuView }){
  return(
    <>
      <div id="meters" class="sidenav">
        <ListGroup flush>

          <ListGroupItem
            href="#"
            tag="a"
            onClick={() => openMenuView('haz')}>
            EPI-HAZ
            <span className="nextbtn">
              <i className="now-ui-icons arrows-1_minimal-right"></i>
            </span>
            <hr />
          </ListGroupItem>

          <ListGroupItem
            href="#"
            tag="a"
            onClick={() => openMenuView('gen')}>
            EPI-GEN
            <span className="nextbtn">
              <i className="now-ui-icons arrows-1_minimal-right"></i>
            </span>
            <hr />
          </ListGroupItem>

          <ListGroupItem
            href="#"
            tag="a"
            onClick={() => openMenuView('val')}
          >
            EPI-VAL
            <span className="nextbtn">
              <i className="now-ui-icons arrows-1_minimal-right"></i>
            </span>
            <hr />
          </ListGroupItem>
          <ListGroupItem
            href="#"
            tag="a"
            onClick={() => openMenuView('pure')}
          >
            EPI-PURE
            <span className="nextbtn">
              <i className="now-ui-icons arrows-1_minimal-right"></i>
            </span>
            <hr />
          </ListGroupItem>
          <ListGroupItem
            href="#"
            tag="a"
            onClick={() => openMenuView('xt')}
          >
            EPI-XT
            <span className="nextbtn">
              <i className="now-ui-icons arrows-1_minimal-right"></i>
            </span>
            <hr />
          </ListGroupItem>
          <ListGroupItem
            href="#"
            tag="a"
            onClick={() => openMenuView('multi')}
          >
            EPI-MULTIGEN
            <span className="nextbtn">
              <i className="now-ui-icons arrows-1_minimal-right"></i>
            </span>
            <hr />
          </ListGroupItem>
          <ListGroupItem
            href="#"
            tag="a"
            onClick={() => openMenuView('switch')}
          >
            EPI-SWITCH
            <span className="nextbtn">
              <i className="now-ui-icons arrows-1_minimal-right"></i>
            </span>
            <hr />
            </ListGroupItem>
        </ListGroup>
      </div>
    </>
  )
}

export default MeterPage;
