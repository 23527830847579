import React, { useEffect, useState, lazy, Suspense } from "react";
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import useSwr from 'swr';
import fetcher from 'utils/fetcher';
import ShoppingCart from 'components/Customizer/ShoppingCart';
import './MobileSideNavbar.css';
import './NavbarSelectProduct.css';

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import MenuHeader from "components/Headers/MenuHeader";
// import MobileMenuHeader from "components/Headers/MobileMenuHeader";
// import ProductViewSidebar from "../ProductSidebar/ProductViewSidebar";

import MainPage from "./Mobile/MainPage";
import MeterPage from "./Mobile/MeterPage";
import SupportPage from "./Mobile/SupportPage";
import AccoutPage from "./Mobile/AccountPage";

const Haz = lazy(() => import('./Mobile/meters/Haz'));
const Gen = lazy(() => import('./Mobile/meters/Gen'));
const Val = lazy(() => import('./Mobile/meters/Val'));
const Pure = lazy(() => import('./Mobile/meters/Pure'));
const XT = lazy(() => import('./Mobile/meters/XT'));
const Switch = lazy(() => import('./Mobile/meters/Switch'));
const Multi = lazy(() => import('./Mobile/meters/Multi'));


function MainNavbar() {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [navPosition, setNavPosition] = useState('fixed-top');
  const [mobileActivePosistion, setMobileActivePosistion] = useState(true);
  const [mobileActivePosistionBack, setMobileActivePosistionBack] = useState(false);
  const [prevPage, setPrevPage] = useState(false);
  const [slidePageDepth, setSlidePageDepth] = useState(0);
  const [selectProductStatus, setSelectProductStatus] = useState(false);
  const [cartItems, setCartItems] = useState(0);
  const [pageViewHist, setPageViewHist] = useState({
    0: 'main',
  });

  const location = useLocation();
  const page = location.pathname.split('/')[1]

  useEffect(() => {
    const updatePosition = () => {
      if (
        document.documentElement.scrollTop > 800 ||
        document.body.scrollTop > 800
      ) {
        setNavPosition('fixed-top');
      } else {
        setNavPosition('fixed-top');
      }
    };

    window.addEventListener("scroll", updatePosition);
    return function cleanup() {
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  const isAuthenticated_ = JSON.parse(sessionStorage.getItem('ciuli'));

  const { data } = useSwr(isAuthenticated_ ? `/api/me` : null, fetcher);
  useEffect(() => {
    if(data){
      setUserData(data);
    }

    if(data?.account_type === "banned"){
      logout();
    }
  }, [data]);

  useEffect(() => {
    const cart = ShoppingCart({'task':'read'});
    setCartItems(cart.ci);
  }, []);

  const logout = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/sessions`,
        { withCredentials: true }
      )
      sessionStorage.setItem('ciuli', JSON.stringify(false));
      history.go("/");
    } catch (e) {
      console.log('Show me logout error 1.0 =>', e.message);
    }
  };

  const mobileNavbarState = () => {
    const productSidebar = document.getElementById("productSidebar");
    if(productSidebar){
      if(productSidebar.style.width === '100%'){
        productSidebar.style.width = "0";
        return
      }
    }

    setMobileActivePosistion(!mobileActivePosistion);
    if(mobileActivePosistion){
      document.getElementById("main").style.width = "100%";
      window.onscroll = () => { window.scroll(0, 0); };
    }

    if(!mobileActivePosistion){
      for (const key in pageViewHist) {
        document.getElementById(pageViewHist[key]).style.width = "0";
      }

      if(prevPage){
        document.getElementById(prevPage).style.width = "0"
        setMobileActivePosistionBack(false);
      }
      window.onscroll = () => {};
    }
  }

  const openMenuView = (view) => {
    setMobileActivePosistionBack(true);
    setPrevPage(view)
    setSlidePageDepth(slidePageDepth + 1);
    setPageViewHist(prevState => ({
      ...prevState,
      [slidePageDepth + 1]: view
    }));
    console.log('Show me menu depth 1.0 =>', pageViewHist);
    document.getElementById(view).style.width = "100%";
  }

  const openMenuViewBack = () => {
    console.log('Show me menu depth 1.1 =>', slidePageDepth);
    setSlidePageDepth(slidePageDepth - 1);

    if(slidePageDepth == 0){
      setMobileActivePosistionBack(false);
    }
    const prevPage_ = pageViewHist[slidePageDepth]
    document.getElementById(prevPage_).style.width = "0"
  }

  const showTopSlider = () => {
    setSelectProductStatus(!selectProductStatus);
    if(selectProductStatus){
      document.getElementById('slideTop').style.minHeight = "0";
      document.getElementById('slideTop').style.height = "0";
    } else {
      document.getElementById('slideTop').style.minHeight = "700px";
      document.getElementById('slideTop').style.height = "auto";
    }
  }

  const closeTopSlider = () => {
    document.getElementById('slideTop').style.minHeight = "0";
    document.getElementById('slideTop').style.height = "0";
  }

  if(isMobile){
    if(selectProductStatus){
      setSelectProductStatus(!selectProductStatus);
      document.getElementById('slideTop').style.height = "0";
    }
  }

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
      {isMobile && (
        <>
          <MainPage openMenuView={openMenuView} />
          <MeterPage openMenuView={openMenuView} />
          <SupportPage mobileNavbarState={mobileNavbarState} />
          <AccoutPage
            openMenuView={openMenuView}
            mobileNavbarState={mobileNavbarState}
            userData={userData}
            logout={logout}
          />
          <Haz
            openMenuView={openMenuView}
            mobileNavbarState={mobileNavbarState}
          />
          <Gen
            openMenuView={openMenuView}
            mobileNavbarState={mobileNavbarState}
          />
          <Val
            openMenuView={openMenuView}
            mobileNavbarState={mobileNavbarState}
          />
          <Pure
            openMenuView={openMenuView}
            mobileNavbarState={mobileNavbarState}
          />
          <Switch
            openMenuView={openMenuView}
            mobileNavbarState={mobileNavbarState}
          />
          <XT
            openMenuView={openMenuView}
            mobileNavbarState={mobileNavbarState}
          />
          <Multi
            openMenuView={openMenuView}
            mobileNavbarState={mobileNavbarState}
          />
        </>
      )}
      {
        collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null
    }
      <Navbar className={navPosition} color="info" expand="lg">
        <Container>
          <div className="navbar-translate">
            {!mobileActivePosistionBack?
              (
                <NavbarBrand
                  href="/"
                  id="navbar-brand"
                >
                  <img
                    class="logoPrimary"
                    src="/logo-primary.png"
                    alt="EPI Logo" 
                  />
                  <p className="align-bottom fw-bold">ISO 9001 Certified.</p>
                </NavbarBrand>
              ) : (
                <button
                className="navbar-toggler navbar-toggler"
                data-toggle="dropdown"
                href="#"
                id="linkTogglerMobile"
                type="button"
                onClick={() => openMenuViewBack()}
              >
                <i className="now-ui-icons arrows-1_minimal-left" style={{ 'font-size': '25px', 'color': '#095CF6'}} ></i>
              </button>
              )
            }
            <button
              className="navbar-toggler navbar-toggler"
              data-toggle="dropdown"
              href="#"
              type="button"
              onClick={() => mobileNavbarState()}
            >
              <span className="navbar-toggler-bar top-bar" style={{ 'color': 'black'}}></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>

          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem className={page === "products" && "active"}>
                <UncontrolledDropdown>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    href="#"
                    nav
                    onClick={() => showTopSlider()}
                  >
                    SELECT PRODUCT
                  </DropdownToggle>
                </UncontrolledDropdown>
              </NavItem>
              <NavItem className={page === "findrep" && "active"}>
                <NavLink to="/findrep" tag={Link} onClick={() => closeTopSlider()}>
                  FIND A REP
                </NavLink>
              </NavItem>
              <NavItem className={page === "technicalinfo" && "active"}>
                <NavLink to="/technicalinfo" tag={Link} onClick={() => closeTopSlider()}>
                  TECHNICAL INFO
                </NavLink>
              </NavItem>
              <NavItem className={page === "support" && "active"}>
                <NavLink to="/support" tag={Link} onClick={() => closeTopSlider()}>
                  GET SUPPORT
                </NavLink>
              </NavItem>
              <NavItem className={page === "aboutus" && "active"}>
                <NavLink to="/aboutus" tag={Link} onClick={() => closeTopSlider()}>
                  ABOUT
                </NavLink>
              </NavItem>
              {userData._id? (
                <UncontrolledDropdown nav className={page === "portal" && "active"}>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    href="#"
                    id="navbarDropdownMenuLink"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <i className="now-ui-icons users_circle-08" style={{ 'font-size': '17px' }}></i>
                    { userData.firstname } { userData.lastname }
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      href="/portal/quotes"
                    >
                      Account
                    </DropdownItem>
                    <DropdownItem
                      href="/portal/editAccount"
                    >
                      Settings
                    </DropdownItem>
                    <div className="divider"></div>
                    <DropdownItem
                      href="/portal/becomeAdistributor"
                    >
                      Become a Distributor
                    </DropdownItem>
                    <div className="divider"></div>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => logout()}
                    >
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <NavItem className={page === "login" && "active"}>
                  <NavLink from="*" tag={Link} to="/login">
                    Login
                  </NavLink>
                </NavItem>
              )}
              <NavItem className={page === "quote" && "active"}>
                <NavLink to="/portal/cart" tag={Link} onClick={() => closeTopSlider()} className='cartIcon'>
                  <FontAwesomeIcon icon={faShoppingCart} className='icon'></FontAwesomeIcon><sub>{cartItems?.length}</sub>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {/* <ProductViewSidebar /> */}
      <div id="slideTop" class="sidenavPro productDropdown" style={{'padding-top': '0px'}}>
        <MenuHeader showTopSlider={showTopSlider} />
      </div>
      {/* { isMobile? (<MobileMenuHeader />) : (<MenuHeader menuHeaderStatus={menuHeaderStatus} setMenuHeaderStatus={setMenuHeaderStatus} />) } */}
      </Suspense>
    </>
  );
}

export default MainNavbar;
