import React from "react";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";
import '../MobileSideNavbar.css';

function AccoutPage({ openMenuView, mobileNavbarState, userData, logout }){
  return(
    <>
      <div id="account" class="sidenav">
        {userData._id? (
          <ListGroup flush>
            <ListGroupItem
              href="#"
              tag="a"
            >
              Account
              <hr />
            </ListGroupItem>
            <ListGroupItem
              href="#"
              tag="a"
            >
              Settings
              <hr />
            </ListGroupItem>
            <ListGroupItem
              href="#"
              tag="a"
              onClick={(e) => logout()}
            >
              Log Out
              <span className="nextbtn">
                <i className="now-ui-icons arrows-1_minimal-right"></i>
              </span>
            </ListGroupItem>
          </ListGroup>
        ) : (
          <ListGroup flush>
            <ListGroupItem
              tag={Link}
              from="*"
              to="/login"
              onClick={() => mobileNavbarState()}
            >
              Log In
              <hr />
            </ListGroupItem>
            <ListGroupItem
              tag={Link}
              from="*"
              to="/signup"
              onClick={() => mobileNavbarState()}
            >
              Sign Up
              <hr />
            </ListGroupItem>
          </ListGroup>
        )}
      </div>
    </>
  )
}

export default AccoutPage;
