import React from "react";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";
import '../MobileSideNavbar.css';

function MainPage({ openMenuView }){
  return(
    <div id="main" class="sidenav">
      <ListGroup flush>
        <ListGroupItem
          href="#"
          tag="a"
          onClick={() => openMenuView('meters')}
        >
          <span className="nextbtn">
            <i className="now-ui-icons arrows-1_minimal-right"></i>
          </span>
          SELECT METER
          <hr />
        </ListGroupItem>
        <ListGroupItem
          href="#"
          tag="a"
          tag={Link}
          to="/support/findrep"
        >
          FIND A REP
          <hr />
        </ListGroupItem>
        <ListGroupItem
          href="/technicalinfo"
          tag="a"
        >
          TECHNICAL INFO
          <hr />
        </ListGroupItem>
        <ListGroupItem
          href="#"
          tag="a"
          onClick={() => openMenuView('support')}
        >
          <span className="nextbtn">
            <i className="now-ui-icons arrows-1_minimal-right"></i>
          </span>
          GET SUPPORT
          <hr />
        </ListGroupItem>
        <ListGroupItem
          href="#"
          tag="a"
          onClick={() => openMenuView('account')}
        >
          <span className="nextbtn">
            <i className="now-ui-icons arrows-1_minimal-right"></i>
          </span>
          ACCOUNT
          <hr />
        </ListGroupItem>
        <ListGroupItem href="/aboutus" tag="a">
          ABOUT US
        </ListGroupItem>
        <ListGroupItem href="/contactus" tag="a">
          CONTACT US
        </ListGroupItem>
        <ListGroupItem href="/support/faq" tag="a">
          FAQ
        </ListGroupItem>
      </ListGroup>
    </div>
  )
}

export default MainPage;
